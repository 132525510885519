import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginBanner: {
    backgroundColor: theme.palette.primary.main,
    // padding: theme.spacing(8),
    height: "248px",
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textContainer: {
    textAlign: "center"
  },
  title: {
    color: "#F9F9F9",
    fontSize: "60px",
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

const GCBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.loginBanner}>
      <div className={classes.textContainer}>
        <span className={classes.title}>GC AdvantagePoint</span>
        {/* <br />
        <span className={classes.subTitle}>A tagline will go here</span> */}
      </div>
    </div>
  );
};

export default GCBanner;
