import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Button, SvgIcon, Grid } from "@material-ui/core";
import underwriter from "../../assets/card-images/Underwriter+.png";
import eventImpact from "../../assets/card-images/EventImpact.png";
import portfolioInsight from "../../assets/card-images/PortfolioInsight.png";
import geoPortal from "../../assets/card-images/GeoPortal.png";
import portfolioUpload from "../../assets/card-images/PortfolioUpload.png";
import admin from "../../assets/card-images/Admin.png";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import SecurityIcon from "@material-ui/icons/Security";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PollIcon from "@material-ui/icons/Poll";
import PublicIcon from "@material-ui/icons/Public";
import SettingsIcon from "@material-ui/icons/Settings";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ClientGroupSelector from "../client-group-selector/ClientGroupSelector";
import Footer from "../footer/Footer";
import GCBanner from "../gc-banner";

import {
  setSnackBarMessage,
  setSnackBarOpen,
  setSnackBarSeverity,
} from "../../reducers/utilsSlice";

import {config} from "../../config";
const {
  text,
  apps
} = config;

const AppAccessList = {
  // underwriter: {
  //   icon: ReportProblemIcon,
  //   image: underwriter,
  //   title: "Underwriter+",
  //   description:
  //     "Make more profitable underwriting decisions with instant access to accumulation, hazard, guideline and loss information.",
  // },
  eventImpact: {
    icon: SecurityIcon,
    image: eventImpact,
    title: "Event Impact",
    description:
      "Leverage the power of our real-time event feeds across a range of natural perils.  Quickly identify events of consequence, view the potential impact and respond quickly.",
  },
  portfolioInsight: {
    icon: PollIcon,
    image: portfolioInsight,
    title: "Portfolio Insight",
    description:
      "Utilize our best-in-class mapping experience to generate detailed analyses across multiple event layers and portfolios of interest.",
  },
  // geoPortal: {
  //   icon: PublicIcon,
  //   image: geoPortal,
  //   title: "GeoPortal",
  //   description:
  //     "Make more profitable underwriting decisions with instant access to accumulation, hazard, guideline and loss information.",
  // },
  portfolioUpload: {
    icon: CloudUploadIcon,
    image: portfolioUpload,
    title: "Portfolio Upload Tool",
    description:
      "Upload and manage your own portfolio data loads into the platform.  Utilize custom templates for data mapping to streamline loading and validation.",
  },
  // admin: {
  //   icon: SettingsIcon,
  //   image: admin,
  //   title: "Admin",
  //   description:
  //     "Make more profitable underwriting decisionswith instant access to accumulation, hazard, guideline and loss information.",
  // },

  // modelDataUpload: {
  //   icon: CloudUploadIcon,
  //   image: admin,
  //   title: "Model Data Upload Tool",
  //   description:
  //     "Leverage the power of our real-time event feeds across a range of natural perils.  Quickly identify events of consequence, viewthe potential impact and respond quickly.",
  // },

  support: {
    icon: LiveHelpIcon,
    image: admin,
    title: "Support",
    description:
      "Contact support with any questions you have or issues you run into.",
  },
};

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(8),
  },
  container: {
    width: "80%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyItems: "center",
  },
  icon: {
    top: "1px",
    width: "20px",
    height: "20px",
    color: theme.palette.primary.main,
    verticalAlign: "text-top",
  },
  card: {
    display: "flex",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    width: "476px",
    height: "174px",
  },
  cardContent: {
    padding: theme.spacing(2),
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardBody: {
    flexGrow: 1,
  },
  cardIcon: {
    width: "174px",
    objectFit: "cover"
  },
  cardTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  description: {
    fontSize: "14px",
  },
  mt1: {
    marginTop: theme.spacing(5),
  },
  mainGrid: {
    width: "1460px",
    margin: "auto",
  },
}));

const Landing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.login.loginUser);
  const [appAccessKeys, setAppAccessKeys] = useState([]);
  const [generalAppAccessKeys, setGeneralAppAccessKeys] = useState([]);

  const [noClientGroupSelected, setNoClientGroupSelection] = useState(false);
  const [open, setOpen] = useState(false);
  const [appTitle, setAppTitle] = useState();
  const [appToOpen, setAppToOpen] = useState(null);

  const DEV_BASE_URL = process.env.REACT_APP_DEV_BASE_URL;

  useEffect(() => {
    if (loginUser) {
      setGeneralAppAccessKeys(Object.keys(loginUser.generalAppAccess));
      setAppAccessKeys(Object.keys(apps));
    }
  }, [loginUser]);

  const handleClientSelector = (key) => {
    const selectedApp = apps[key];
    setAppToOpen(selectedApp);
    if (key === "support") {
      window.open(`mailto:${config.apps.support.email}`);
      return;
    }

    if (key === "admin") {
      if (loginUser.admin) {
        window.open(selectedApp.targetUrl);
        return;
      } else {
        dispatch(setSnackBarMessage("Access denied"));
        dispatch(setSnackBarSeverity("error"));
        dispatch(setSnackBarOpen(true));
        return;
      }
    }

    if (selectedApp.hasClientGroup) {
      if (generalAppAccessKeys.includes(key)) {
        if (loginUser.generalAppAccess[key]) {
          setOpen(true);
        } else {
          dispatch(setSnackBarMessage("Access denied"));
          dispatch(setSnackBarSeverity("error"));
          dispatch(setSnackBarOpen(true));
        }
      } else {
        setOpen(true);
      }
    } else {
      if (generalAppAccessKeys.includes(key)) {
        if (loginUser.generalAppAccess[key]) {
          window.open(selectedApp.targetUrl);
        } else {
          dispatch(setSnackBarMessage("Access denied"));
          dispatch(setSnackBarSeverity("error"));
          dispatch(setSnackBarOpen(true));
        }
      } else {
        setOpen(true);
      }
    } 
  };

  return (
    <div className={classes.content}>
      <GCBanner />
      {/* <div className={classes.loginBanner}>
        <span className={classes.title}>GC AdvantagePoint</span>
        <br />
        <span className={classes.subTitle}>A tagline will go here</span>
      </div> */}
      <div className={classes.mainGrid}>
        <Grid container spacing={2}>
          {appAccessKeys.length > 0 &&
            appAccessKeys.map((key, index) => (
              <Grid key={index} item xs={4}>
                <div className={classes.card}>
                  <img
                    src={apps[key].image}
                    className={classes.cardIcon}
                  />
                  <div className={classes.cardContent}>
                    <div className={classes.cardBody}>
                      <div className={classes.cardTitleContainer}>
                        <SvgIcon
                          component={apps[key].icon}
                          className={classes.icon}
                        />
                        <span className={classes.cardTitle}>
                          {apps[key].title}
                        </span>
                      </div>
                      <div>
                        <span className={classes.description}>
                          {apps[key].description}
                        </span>
                      </div>
                    </div>
                    <Button
                      variant="text"
                      color="default"
                      fullWidth
                      onClick={(event) => handleClientSelector(key)}
                    >
                      {key === "support" ? "Contact Us" : "Launch"}
                    </Button>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
        {/* <div className={classes.container}>
          {appAccessKeys.length > 0 &&
            appAccessKeys.map((key, index) => (
              <div key={index} className={classes.card}>
                <img
                  src={AppAccessList[key].image}
                  className={classes.cardIcon}
                />
                <div className={classes.cardContent}>
                  <div className={classes.cardBody}>
                    <SvgIcon
                      component={AppAccessList[key].icon}
                      className={classes.icon}
                    />
                    <span className={classes.cardTitle}>
                      {AppAccessList[key].title}
                    </span>
                    <br />
                    <span className={classes.description}>
                      {AppAccessList[key].description}
                    </span>
                  </div>
                  <Button
                    variant="text"
                    color="default"
                    fullWidth
                    onClick={(event) =>
                      handleClientSelector(AppAccessList[key].title)
                    }
                  >
                    LAUNCH
                  </Button>
                </div>
              </div>
            ))}
        </div> */}
      </div>
      <ClientGroupSelector setOpen={setOpen} open={open} appToOpen={appToOpen} />
      <Footer />
    </div>
  );
};

export default Landing;
