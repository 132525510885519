import React, {useEffect} from "react";

import { theme } from "@gcui/react";
import { ThemeProvider } from "@material-ui/core/styles";

import Navbar from "./components/header";
import Main from "./containers/main";
// import SnackBar from "./components/shared/snackbar/SnackBar";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <Main />
        {/* <SnackBar /> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
