import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as AdminApi from "../shared/apis/AdminApi";
import { cloneDeep, groupBy, omit, mapValues } from "lodash";

const initialState = {
  loginUser: null,
  userClients: [],
  selectedClient: null,
  selectedGroup: null,
  status: "idle",
  error: null,
};

export const fetchLoginUser = createAsyncThunk(
  "admin/fetchLoginUser",
  async (parameter) => {
    const response = await AdminApi.getLoginUser();
    if (response.status === 200) {
      let user = response.data;
      const groups = cloneDeep(user.userRoles.details);
      const clientGroups = mapValues(groupBy(groups, "clientId"), (rlist) =>
        rlist.map((role) => omit(role, "clientId"))
      );
      const clients = groups.filter(
        (v, i, a) => a.findIndex((t) => t.clientId === v.clientId) === i
      );
      const final = clients.map((client) => {
        return {
          clientId: client.clientId,
          clientName: client.clientName,
          groups: clientGroups[client.clientId]
            ? clientGroups[client.clientId]
            : [],
        };
      });

      const filterClients = final.filter(
        (f) => f.groups && f.groups.length > 0
      );
      const withImages = await getClientImages(filterClients);

      user = {
        ...user,
        validClients: withImages,
      };
      // console.log(JSON.stringify(user));
      return user;
    } else {
      return response.data;
    }
  }
);

const getClientImages = async (clients) => {
	const result = await Promise.all(clients.map( async (client) => {
		let c = {
			...client,
			imageUrl: await AdminApi.getClientImage(client.clientId)
				.then(res => {
					if (res.data instanceof Blob) {
						let unsafeImageUrl = URL.createObjectURL(res.data);
						return unsafeImageUrl;
					} else {
						return ''
					}
				}).catch(error => {
					return ''
				})
		};

		return c;
	}));
	return result;
};


export const loginReducerSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    saveLoginInfo: (state, action) => {
      state.loginUser = action.payload;
    },
  },
  extraReducers: {
    [fetchLoginUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchLoginUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const user = action.payload;
      state.loginUser = user;

      const valid = user.validClients.length > 0 ? user.validClients : [];
      const sorted = valid.sort((a, b) =>
        a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
      );
      const sortedGroups = sorted.map((s) => {
        return {
          ...s,
          groups: s.groups.sort((a, b) =>
            a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0
          ),
        };
      });
      state.userClients = sortedGroups;
      if (valid.length > 0) {
        if (valid[0]) {
          state.selectedClient = valid[0];
          if (valid[0].groups && valid[0].groups.length > 0) {
            if (valid[0].groups[0]) {
              state.selectedGroup = valid[0].groups[0];
            }
          }
        }
      }
    },
    [fetchLoginUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { saveLoginInfo } = loginReducerSlice.actions;

export default loginReducerSlice.reducer;
