import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Divider from "@material-ui/core/Divider";

import { GcLogo } from "@gcui/react";
import { ReactComponent as GCLogo2 } from "../../assets/gc-logo-trademark/gc-advantage-point-logo.svg";
import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  gcTopAppBar: {
    backgroundColor: "rgb(255, 255, 255) !important",
    color: "black !important",
  },
  logoContainer: {
    width: "200px",
    margin: "auto",
    paddingTop: "4px",
    marginRight: "16px",
  },

  navDivider: {
    margin: "11px 8px",
    background: "black",
  },

  navbarTitle: {
    marginLeft: "24px",
    marginRight: "12px",
    fontSize: "20px",
    height: "28px",
    fontWeight: "bold",
    paddingTop: "4px",
  },
  hidden: {
    visibility: "hidden",
  },
  logout: {
    color: theme.palette.primary.main,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const handleLogout = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.gcTopAppBar}>
          <div className={classes.logoContainer}>
            {/* <img className='AppLogo' src='/guy-carpenter-logo.svg' alt="Home"/> */}
            <GcLogo style={{ marginTop: "4px" }} />
          </div>
          {/* <span className={classes.navbarTitle}>GC AdvantagePoint</span> */}
          <GCLogo2 />
          <Divider
            className={classes.navDivider}
            orientation="vertical"
            flexItem
            style={{ marginLeft: "16px" }}
          />
          <div className={classes.grow} />
          {/* {path !== "/login" && (
            <IconButton
              className={classes.logout}
              onClick={(event) => handleLogout(event)}
            >
              <ExitToAppIcon fontSize="large" />
            </IconButton>
          )} */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
