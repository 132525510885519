import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackBarOpen: false,
  snackBarMessage: '',
  snackBarDuration: 6000,
  snackBarSeverity: 'info',
  isCookie: false
};

const utilsSlice = createSlice({
  name: "utilsSlice",
  initialState,
  reducers: {
    setSnackBarOpen: (state, action) => {
      state.snackBarOpen = action.payload;
    },
    setSnackBarMessage: (state, action) => {
      state.snackBarMessage = action.payload;
    },
    setSnackBarDuration: (state, action) => {
      state.snackBarDuration = action.payload;
    },
    setSnackBarSeverity: (state, action) => {
      state.snackBarSeverity = action.payload;
    },
    setIsCookie: (state, action) => {
      state.isCookie = action.payload;
    },
  }
});

export const { setSnackBarOpen, setSnackBarMessage, setSnackBarDuration, setSnackBarSeverity, setIsCookie } = utilsSlice.actions;
export default utilsSlice.reducer;
