import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, TextField, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginBanner: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(8),
    textAlign: "center",
    padding: theme.spacing(8),
  },
  title: {
    color: "#F9F9F9",
    fontSize: "60px",
  },
  subTitle: {
    color: theme.palette.common.white,
    fontSize: "20px",
    fontWeight: "bold",
  },
  loginBox: {
    width: "45%",
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(2),
  },
  loginTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "20px",
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  loginBtn: {
    marginTop: theme.spacing(2),
    fontSize: "15px",
    fontWeight: "bold",
  },
  forgotLabel: {
    fontSize: "12px",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enteredUsername, setEnteredUsername] = React.useState("");
  const [enteredPassword, setEnteredPassword] = React.useState("");

  let handleUsernameChange = (event) => {
    setEnteredUsername(event.target.value);
  };

  let handlePasswordChange = (event) => {
    setEnteredPassword(event.target.value);
  };

  // let sendMockResponse = () => {
  //   fetch("./loginUser.json")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       dispatch(saveLoginInfo(data));
  //       navigate("/home");
  //     })
  //     .catch((err) => {
  //       console.log("Error Reading data " + err);
  //     });
  // };

  return (
    <>
      <div className={classes.loginBanner}>
        <span className={classes.title}>GC AdvantagePoint</span>
        <br />
        <span className={classes.subTitle}>A tagline will go here</span>
      </div>
      <div className={classes.loginBox}>
        <span className={classes.loginTitle}>Login</span>
        <div className={classes.mt2}>
          <TextField
            id="login-username"
            variant="outlined"
            fullWidth
            placeholder="Username"
            value={enteredUsername}
            onChange={handleUsernameChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setEnteredUsername("")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
          <Link
            className={classes.forgotLabel}
            href="#"
            underline="hover"
            color="textSecondary"
          >
            Forgot Username?
          </Link>
        </div>
        <div className={classes.mt2}>
          <TextField
            id="login-password"
            variant="outlined"
            fullWidth
            type="password"
            placeholder="Password"
            value={enteredPassword}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setEnteredPassword("")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
          <Link
            className={classes.forgotLabel}
            href="#"
            underline="hover"
            color="textSecondary"
          >
            Forgot Password?
          </Link>
        </div>
        <Button
          className={classes.loginBtn}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={(event) => {
            navigate("/landing");
          }}
        >
          LOGIN
        </Button>
      </div>
    </>
  );
};

export default LoginPage;
