// @scripts
import initialState from "./initial-state";
import language from "./text";
import gcApplications from "./gc-applications"


const getConfiguration = () => {
  const config = {
    text: language[initialState.language],
    apps: gcApplications
  };

  global.config = config;

  return config;
};

export const config = getConfiguration();
