import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  fpContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    background: "#f8f8f8ad",
    zIndex: "9999999",
  },

  fpLoader: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "99999",
    position: "absolute",
  },
}));

const FullPageLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.fpContainer}>
      <div className={classes.fpLoader}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default FullPageLoader;
