import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: "160px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "16px",
  },
  body: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
}));

const Unauthorized = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <Typography variant="h4" component="div">
          401 - Unauthorized
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography variant="body1" component="div">
          Your authentication failed.
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography variant="body1" component="div">
          Please try to refresh your browser to login again.
        </Typography>
      </div>
    </div>
  );
};

export default Unauthorized;
