import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

// @components
// import DialogTitle from "../../atoms/dialog-title";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// @styles
import styles from "./styles";
import { Avatar, ListItemAvatar, withStyles } from "@material-ui/core";

// @scripts
import { config } from "../../config";
// import {
//   setSelectedClient,
//   setSelectedGroup,
// } from "../../../features/user/userSlice";

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title} {...other}>
      <Typography variant="h6" className={classes.titleText}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const DEV_BASE_URL = process.env.REACT_APP_DEV_BASE_URL;

const LoginDialog = ({
  classes,
  setOpen,
  open,
  appToOpen,
  appTitle = "default",
}) => {
  const [clientData, setClientData] = useState({});
  const [groupData, setGroupData] = useState({});

  const [filteredClients, setFilteredClients] = useState([]);

  const eventImpactId = "0d9ded9a-be7c-4508-b708-6083c05fe74b";
  const underWriterId = "1a4729a5-ce9e-42d1-b983-d95bf72dce40";

  const { loginUser, loginUserStatus, userClients, selectedGroup } =
    useSelector((state) => ({
      loginUser: state.login.loginUser,
      loginUserStatus: state.login.status,
      userClients: state.login.userClients,
      selectedGroup: state.login.selectedGroup,
    }));

  const dispatch = useDispatch();

  const setClientAndGroup = (app) => {
    if (app) {
      if (app.filterable) {
        const validClients = filterApplications(loginUser, app.appId);
        patchDropdown(validClients);
      } else {
        patchDropdown(userClients);
      }
    } else {
      patchDropdown(userClients);
    }

    // if (app === "eventImpact") {
    //   const validClients = filterApplications(loginUser, eventImpactId);
    //   patchDropdown(validClients);
    // } else if (app === "underwriter") {
    //   const validClients = filterApplications(loginUser, underWriterId);
    //   patchDropdown(validClients);
    // } else {
    //   patchDropdown(userClients);
    // }
  };

  useEffect(() => {
    if (loginUserStatus === "succeeded") {
      setClientAndGroup(appToOpen);
    }
  }, [userClients, appToOpen]);

  const filterApplications = (user, appId) => {
    const roles = _.cloneDeep(user.userRoles.details);
    const groupsWithApp = roles.filter(
      (x) =>
        x.applications &&
        x.applications.length > 0 &&
        x.applications.some((a) => a.id === appId)
    );
    const clientGroups = _.mapValues(
      _.groupBy(groupsWithApp, "clientId"),
      (rlist) => rlist.map((role) => _.omit(role, "clientId"))
    );
    const clients = user.validClients.filter(
      (v, i, a) => a.findIndex((t) => t.clientId === v.clientId) === i
    );
    // const clientsWithEventImpact = clients.filter(x => x.applications &&
    // 	x.applications.length > 1 && x.applications.some(a => a.id === '0d9ded9a-be7c-4508-b708-6083c05fe74b'));
    const final = clients.map((client) => {
      return {
        clientId: client.clientId,
        clientName: client.clientName,
        groups: clientGroups[client.clientId]
          ? clientGroups[client.clientId]
          : [],
        imageUrl: client.imageUrl,
      };
    });

    const filterClients = final.filter((f) => f.groups && f.groups.length > 0);
    return final;
  };

  const patchDropdown = (validClients) => {
    if (
      validClients &&
      (validClients.length > 0 ||
        (validClients[0].groups && validClients[0].groups.length > 0))
    ) {
      if (validClients[0]) {
        const [clientValue] = validClients;
        setFilteredClients(validClients);
        setClientData({ ...clientValue });

        if (validClients[0].groups && validClients[0].groups.length) {
          if (validClients[0].groups[0]) {
            const [groupVal] = validClients[0].groups;
            setGroupData({ ...groupVal });
          }
        } else {
          setGroupData(null);
        }
      } else {
        setFilteredClients([]);
        setClientData(null);
        setGroupData(null);
      }
    } else {
      setFilteredClients([]);
      setClientData(null);
      setGroupData(null);
    }
  };

  const changeSelectedGroup = (client) => {
    if (client?.groups?.length) {
      const [groupVal] = client.groups;
      setGroupData({ ...groupVal });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getGroupIndex = (id, array) => {
    if (array?.length) {
      return array.findIndex((element) => element.groupId === id);
    }
    return -1;
  };

  const getClientIndex = (id, array) => {
    if (array?.length) {
      return array.findIndex((element) => element.clientId === id);
    }
    return -1;
  };

  const selectedClientGroupChange = (event) => {
    setGroupData(event.target.value);
  };

  const selectedUserClientChange = (event) => {
    setClientData(event.target.value);
    changeSelectedGroup(event.target.value);
  };

  const selectClientdAndGroup = () => {
    if (appToOpen.hasReceiver) {
      window.open(
        `${appToOpen.targetUrl}/receiver/${clientData.clientId}/${groupData.groupId}`
      );
    } else {
      window.open(`${appToOpen.targetUrl}`);
    }
    // if (appTitle === "admin") {
    //   window.open(
    //     window.location.origin +
    //       "admin/clients/" +
    //       clientData.clientId +
    //       "/" +
    //       groupData.groupId
    //   );
    // } else if (appTitle === "eventImpact") {
    //   window.open(
    //     DEV_BASE_URL +
    //       "ei/receiver/" +
    //       clientData.clientId +
    //       "/" +
    //       groupData.groupId
    //   );
    // } else if (appTitle === "underwriter") {
    //   window.open(DEV_BASE_URL + "uw");
    // } else if (appTitle === "portfolioUpload") {
    //   window.open(DEV_BASE_URL + "portfoliouploadtool");
    // } else {
    //   console.log(clientData);
    //   console.log(groupData);
    // }
    setOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="dynamic-dialog-title"
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
    >
      <DialogTitle
        id="dynamic-dialog-title"
        onClose={handleClose}
        saveText={config.text.labels.ok}
      >
        {config.text.loginDialog.title}
      </DialogTitle>
      <DialogContent dividers>
        {filteredClients?.length > 0 ? (
          <>
            <div className={classes.row}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.clientsDdl}
                fullWidth
              >
                <InputLabel id="clients-label">
                  {config.text.labels.clients}
                </InputLabel>
                <Select
                  id="clients"
                  labelId="clients-label"
                  className={classes.menuContainer}
                  name="clients"
                  data-testid="clients-dropdown"
                  value={
                    clientData
                      ? filteredClients[
                          getClientIndex(clientData?.clientId, filteredClients)
                        ]
                      : filteredClients[0]
                  }
                  onChange={selectedUserClientChange}
                  label={config.text.labels.clients}
                >
                  {filteredClients?.map((client) => (
                    <MenuItem key={client.clientId} value={client}>
                      <ListItemAvatar>
                        <Avatar
                          className={classes.avatarMenuItem}
                          alt={client.clientName}
                          src={client.imageUrl}
                        />
                      </ListItemAvatar>
                      {client.clientName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.row}>
              {clientData && clientData.groups.length > 0 ? (
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.groupsDdl}
                  fullWidth
                >
                  <InputLabel id="groups-label">
                    {config.text.labels.groups}
                  </InputLabel>
                  <Select
                    id="groups"
                    labelId="groups-label"
                    name="groups"
                    data-testid="groups-dropdown"
                    value={
                      clientData.groups[
                        getGroupIndex(groupData?.groupId, clientData.groups)
                      ] || ""
                    }
                    onChange={selectedClientGroupChange}
                    label={config.text.labels.groups}
                  >
                    {clientData?.groups.map((group) => (
                      <MenuItem key={group.groupId} value={group}>
                        {group.groupName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography color="error" variant="caption" style={{marginLeft: "8px"}}>
                  *No groups found. Make sure your assigned groups has access to
                  the application.
                </Typography>
              )}
            </div>
          </>
        ) : (
          <div className={classes.row}>
            <Typography color="error" variant="caption" style={{marginLeft: "8px"}}>
              *No clients found. Make sure your assigned clients has access to
              the application.
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          {config.text.labels.cancel}
        </Button>
        <Button
          variant="contained"
          onClick={selectClientdAndGroup}
          color="primary"
        >
          {config.text.labels.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  setOpen: PropTypes.bool,
  open: PropTypes.bool,
  appToOpen: PropTypes.object,
  appTitle: PropTypes.string,
};

LoginDialog.defaultProps = {
  classes: Function.Object,
  setOpen: PropTypes.bool,
  open: PropTypes.bool,
  appToOpen: PropTypes.object,
  appTitle: PropTypes.string,
};

export default withStyles(styles)(LoginDialog);
