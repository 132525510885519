import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    color: "#202020",
    fontSize: "12px",
    padding: "0.5rem",
    marginTop: "1rem",
    bottom: 10,
    left: 0,
    width: "100%",
    textAlign: "center",
    position: "fixed",
  },
  link: {
    color: "#202020",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      © 2023 Guy Carpenter Marsh McLennan Use of this application constitutes
      acceptance of our
      <a
        target="_blank"
        href="https://www.guycarp.com/company/about/terms-of-use.html"
        rel="noreferrer"
        className={classes.link}
      >
        {" "}
        Terms and Conditions{" "}
      </a>{" "}
      and
      <a
        target="_blank"
        href="https://www.guycarp.com/company/about/privacy-policy.html"
        rel="noreferrer"
        className={classes.link}
      >
        {" "}
        Privacy Policy
      </a>
      .
    </div>
  );
};

export default Footer;
