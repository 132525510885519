const styles = (theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  titleText: {
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    display: "flex",
    padding: "16px 0 8px 0",
  },
  menuContainer: {
    "& .MuiSelect-selectMenu": {
      display: "flex",
      alignItems: "center"
    }
  },
  avatarMenuItem: {
    height: "32px",
    width: "32px",
    fontSize: "12px",
  },
});

export default styles;
