import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Landing from "../../components/landing";
import LoginPage from "../../components/login";
import NotFound from "../../components/not-found";
import { fetchLoginUser } from "../../reducers/loginReducer";
import {
  setSnackBarMessage,
  setSnackBarOpen,
  setSnackBarSeverity,
  setIsCookie
} from "../../reducers/utilsSlice";

import { config } from "../../config";

const Main = () => {

  const dispatch = useDispatch();
  const loginUserStatus = useSelector(state => state.login.status);
  const loginUser= useSelector((state) => state.login.loginUser);

  const [cookies, setCookie] = useCookies();
 

  useEffect(() => {
    if (!cookies.APCATCONSENT) {
      dispatch(setIsCookie(true));
      dispatch(setSnackBarMessage(config.text.cookieConsent.message));
      dispatch(setSnackBarSeverity("info"));
      dispatch(setSnackBarOpen(true));
    } else {
      console.log('cookie exist');
      dispatch(setIsCookie(false));
    }
  }, []);

  useEffect(() => {
    if (loginUserStatus === 'idle') {
        dispatch(fetchLoginUser());
    } 

    if (loginUser){
      pendoInitialize(loginUser);
    }

}, [loginUserStatus, dispatch])

const pendoInitialize = (user) => {
  window.pendo.initialize({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: user.firstName + " " + user.lastName,
      type: user.admin ? "admin" : "user",
    },
    // account: {
    //   id: client ? client.clientName : "",
    //   client_name: client ? client.clientName : "",
    //   group_id: group ? group.groupId : "",
    //   group_name: group ? group.groupName : "",
    // },
  });
};

  return (
    <Routes>
      <Route path="/" element={<Landing />}/>
      <Route path="/login" element={<LoginPage />} />
      {/* <Route path="/landing" element={<Landing />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Main;
