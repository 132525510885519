import axios from "../../axios/axios-admin";

export function getLoginUser() {
  return axios.get(`/users/profile`);
}

export function getClientImage(clientId) {
  return axios({
    method: "get",
    url: `/clients/${clientId}/image`,
    responseType: "blob",
  });
}

export function getGroupByGroupId(groupId) {
  return axios({
    method: "get",
    url: `/groups/${groupId}`,
  });
}

export function fetchPortfolioFields(clientId, portfolioId) {
  return axios.get(`/clients/${clientId}/portfolios/${portfolioId}/fields`);
}
