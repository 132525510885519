import React, { useEffect, useState } from "react";
import * as AuthUserApi from "../../axios/authUser-api";
import FullPageLoader from "../shared/fullPageLoader";
import Unauthorized from "../shared/unauthorized";
import PropTypes from "prop-types";

export default function AuthWrapper({ children }) {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await AuthUserApi.getUserAuthStatus(
        process.env.REACT_APP_BASE_AUTH_URL
      );
      if (!data.data.isAuthenticated) {
        const encodedReturnUri = encodeURIComponent(window.location.href);
        window.location.replace(
          `${process.env.REACT_APP_BASE_AUTH_LOGIN_URL}${encodedReturnUri}`
        );
      } else {
        setIsUserAuthenticated(data.data.isAuthenticated);
      }
    };
    if (isUserAuthenticated === null) {
      fetchData().catch((error) => {
        console.error(error);
        setIsUserAuthenticated(false);
      });
    }
  }, [isUserAuthenticated]);

  if (isUserAuthenticated === null) return <FullPageLoader />;

  if (isUserAuthenticated === false) return <Unauthorized />;

  return <div>{children}</div>;
}

AuthWrapper.propTypes = {
  children: PropTypes.node,
};
