import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(() => ({
  notFoundContent: {
    width: '100%',
    marginTop: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px'
  },
  body: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px'
  },
  homeButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFoundContent}>
      <div className={classes.header}>
        <Typography variant="h4" component="div">
          Page Not Found
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography variant="body1" component="div">
          Sorry, the page you&apos;re looking for does not exist.
        </Typography>
      </div>
      <div className={classes.homeButton}>
        <Link to='/'>
          <Button
            variant="outlined" color="primary"
          >
            <HomeIcon style={{ marginRight: '8px' }} /> Home
          </Button>
        </Link>
      </div>
    </div>
  );

};

export default NotFound;
